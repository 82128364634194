'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import store from 'store';
import debounce from 'lodash.debounce';

import UserActions from '../../../actions/UserActions';

import { getParticipantsForProfileByMealType } from '../../../utils/Meals';
import { profileKeyComparator } from '../../../pro/utils/Patients';

import '../../Widgets/header/HeaderDropdown.scss';
import './PrefsDropdown.scss';
import Analytics from '../../../utils/Analytics';

const LOCALSTORE_KEY = 'inhibit_profile_analysis';

export default class PrefsDropdown extends Component {
    static propTypes = {
    };

    static defaultProps = {
        defaultClassName: 'pro-dropdown-container',
    };

    static contextTypes = {
        user: PropTypes.object,
        location: PropTypes.object,
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            isModalOpen: false,
            isParticipantsChanged: false,
            isProfileChanged: false,
        };

        this.debounceSyncAssets = debounce(this.syncAssets, 750);

        // Don't re-run analysis for a half second after inhibited
        this.resetInhibitAnalysis = debounce(this.resetInhibitAnalysis, 500);
    }

    componentDidMount = () => {
        this.debounceSyncAssets();
    }

    inhibitAnalysis = false

    resetInhibitAnalysis = () => {
        this.inhibitAnalysis = false;
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        const { user } = nextContext;

        this.debounceSyncAssets();
    }

    syncAssets = () => {
        const { user, location } = this.context;
        const { capabilities = {}, preferences = {} } = user || {};

        if (location.pathname !== '/meals') {
            this.setState({isModalOpen: false});
            return;
        }

        if (!user || this.inhibitAnalysis || store.get(LOCALSTORE_KEY)) {
            return;
        }

        if (preferences.inhibit_autopopulate || !capabilities.meal_planner) {
            return;
        }

        // If all meals are turned off, we should not prompt, or regenerate.
        const countParticipants = (total, member) => total + member.portion;

        const breakfasts = getParticipantsForProfileByMealType(user, 'Breakfast', false).reduce(countParticipants, 0);
        const lunches = getParticipantsForProfileByMealType(user, 'Lunch', false).reduce(countParticipants, 0);
        const dinners = getParticipantsForProfileByMealType(user, 'Dinner', false).reduce(countParticipants, 0);
        const snacks = getParticipantsForProfileByMealType(user, 'Snack', false).reduce(countParticipants, 0);

        if (!breakfasts && !lunches && !dinners && !snacks) {
            return;
        }

        const { profile_key } = (user.profile_key || {});
        const { isParticipantsChanged, isProfileChanged, reasons } = profileKeyComparator(user, profile_key);
        const isModalOpen = (isParticipantsChanged || isProfileChanged);

        if (isModalOpen && JSON.stringify(this.state.reasons) !== JSON.stringify(reasons)) {
            this.openModal(reasons);
            Analytics.promptToRegenerateMealFeed({Reasons: reasons});
        }
    }

    closingTimeout = null;

    closeModal = (reasons) => {
        const { onHideDropdown } = this.props;

        this.setState({isModalOpen: false, reasons});
        onHideDropdown && onHideDropdown();
    }

    openModal = (reasons) => {
        const { onShowDropdown } = this.props;


        this.setState({isModalOpen: true, reasons});
        onShowDropdown && onShowDropdown();
    }

    onRefreshMealFeed = async () => {
        const { onRefreshMealFeed } = this.props;
        const { reasons } = this.state;
        this.inhibitAnalysis = true;

        this.closeModal();
        Analytics.changeMeals({Reasons: reasons});

        try {
            await onRefreshMealFeed();
            Analytics.changeMealsCompleted({Reasons: reasons});
        } catch (error) {
            Analytics.changeMealsError({Reasons: reasons, Error: error?.message || error});
        }

        this.resetInhibitAnalysis();
    }

    onClickCancel = () => {
        const { reasons } = this.state;
        this.inhibitAnalysis = true;
        this.closeModal();

        // Update the users profile key to their current profile and reset our inhibitor flag
        UserActions.syncProfileKey().then(this.resetInhibitAnalysis);
        Analytics.keepMeals({Reasons: reasons});
    }

    render = () => {
        const { isModalOpen } = this.state;
        const { onClick } = this.props;

        return (
            <>
                <button className="preferences-btn el-toolbar-btn" onClick={onClick}>
                    <i className="icon-settings" />
                    <span>Preferences</span>
                </button>

                <Modal isOpen={isModalOpen}
                    onRequestClose={this.onClickCancel}
                    closeModal={this.onClickCancel}
                    contentLabel="Profile doesn't match Meal Plan"
                    className="preferences-mismatch-modal"
                    overlayClassName="feed-modal-overlay"
                    closeTimeoutMS={250}>

                    <div className="preferences-mismatch-modal-container">
                        <h1>Your preferences have been changed.</h1>
                        <h2>You're about to change the meals you have already planned.</h2>
                        <p>Do you want to change your meal selections to reflect your new preferences or keep them as planned? You can't undo this action.</p>

                        <footer className="prefs-dropdown-footer">
                            <button className="ok-btn" onClick={this.onRefreshMealFeed}>CHANGE MY MEALS</button>
                            <button className="cancel-btn" onClick={this.onClickCancel}>KEEP MY MEALS AS PLANNED</button>
                        </footer>
                    </div>
                </Modal>
            </>
        );
    }
}
